$amount: 3;
$iconBg: $primary;
$iconColor: $light;
$linkColor: $light;
$contactsBg: darken($secondary,8%);
$contactsColor: $light;
$btnHeight: rem(70px);
$iconSize: rem(24px);
$svgSize: rem(55px);
$desktopPosition: 150px;
$breakPointIcons: large;

//////////////////////////////////////////////////////////////// 

@import "../modules/fixedButtonsMixins";

//mobile GAP FOOTER
@include breakpoint($breakPointIcons,max) {
	body {
		padding-bottom: calc(#{$btnHeight} + 6px);
	}
}

.fixedContacts {
	>li {
		&:before {
			@include before;
			background: $iconBg;	
			color: $iconColor;
			font-size: $iconSize;
			line-height: 130%;
			transition: $time font-size;
		}
		&:hover {
			background: lighten($dark, 10%)!important;

			svg {
				transform: scale(.9);
			}
		}
		a {
			color: $linkColor;
		}

		// Icon Examples
		&.beratung, &.spiegelpferd, &.ponyseidank {
			border: {
				top-left-radius: rem(10px);
				bottom-left-radius: rem(10px);
			}
		}
		&.beratung {
			background: $light!important;
			border: {
				width: 5px;
				style: solid;
				color: $primaryBeratung;
			}

			&:hover {
				background: lighten($dark, 10%)!important;
				border: none;
			}
		}
		&.spiegelpferd {
			background: $primarySpiegelpferd!important;

			&:hover {
				background: lighten($dark, 10%)!important;
			}
		}
		&.ponyseidank {
			background: $primaryPonyseidank!important;

			&:hover {
				background: lighten($dark, 10%)!important;
			}
		}
		&.phone {
			&:before {
				@extend .icon;
				@extend .icon-phone;	
			}
		}
 
		&.email {
			&:before {
				@extend .icon;
				@extend .icon-mail;
			}
		}

		&.address {
			&:before {
				@extend .icon;
				@extend .icon-map;
			}
		}
		&.openingHours {
			&:before {
				@extend .icon;
				@extend .icon-clock;
			}
		}
		&.svgIcons {
			&:before {
				display: none!important;
			}
			a {
				display: flex;
				align-items: center;
			}
			svg {
				max-width: $svgSize;
				max-height: $svgSize;
				transition: $time transform;
			}
		}
	}

	// Mobile Styles
	@include breakpoint($breakPointIcons, max) {
		@include mobileFixedContacts;
		z-index: 999;
		overflow: visible;
		border-radius: 5px;
		box-shadow: 6px -2px 15px rgba(0,0,0, .35);

		>li {
			@include mobileLi;
			border: {
				top-left-radius: rem(0px)!important;
				bottom-left-radius: rem(0px)!important;
			}
			background: $iconBg;
			cursor: pointer;
			overflow:visible;

			body.psychosozialeBeratung &, body.spiegelpferd &, body.ponyseidank &, body.securedir & {
				width: calc(100% / 2);
			}
			&:before {
				@include mobileBefore;
				cursor: pointer;
			}
			
			// Content on Hover/Tab
			.inner {
				@include mobileInner;					
				padding: rem(30px) rem($baseGap);
				box-shadow: 6px -2px 15px rgba(0,0,0, .35);
				background: $contactsBg;
				color: $contactsColor;
				
				.phone {
					display: block;
					height: 100%;
					width: auto;
				}
			}
			
			// Hover usally does not need to be adjusted
			&:hover {
				.inner {
					opacity: 1;
					visibility: visible;
					transform: translate(0, -66px); // If the height of the bar is adjusted, you also need to adjust the transform
				}
			}

			&.hide {
				opacity: 0;
				transform: translate(0, -50px);
			}

			// Elements without Tab/Hover
			&.mobileNoHover {
				.inner {
					@include mobileNohoverInner;

					a {
						@include coverLink
					}
				}
			}
			
			&.mobileNoHover {
				// Define icon size e.g. social ícon
				&:before {
					max-width: 16px;
				}
			}
		}
	}

	// Break styles
	@include breakpoint($breakPointIcons) {
		@include desktopFixedContacts;
		top: $desktopPosition;
		transition: top $time;

		@include breakpoint(huge) {
			top: 200px;
		}
		@include breakpoint(extend) {
			top: 300px;
		}
		>li {
			@include desktopLi;
			background: $contactsBg;
			color: $contactsColor;
			padding: 0 rem(40px);
			
			body.internetExplorer & {
				height: 100%; 
				min-width: 360px; // Set min width for IE
			}

			&.address {
				body.internetExplorer & {
					height: auto; // For certain li that have multiple lines
				}
			}

			@include nthOfType;
	
			.inner {
				white-space: nowrap; // Prevent the absolute content to be wrapped
			}

			&:before {
				@include desktopBefore;
				background: $iconBg;	
			}

			// Hover usally does not need to be adjusted
			&:hover {
				transform: none;
				right: 0;
			}

			&.noHover { // Remove or Set Element without hover
				@include desktopNoHover;
				background: $iconBg;

				body.internetExplorer & {
					min-width: auto;
				}

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					
					// Define icon size e.g. social icon
					img {
						max-width: 18px;
					}
				}
				
				// Removing mobile icon
				&:before {
					content: none;	
				}
			}
		}
	}
}
/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 16px;

$baseLineHeight: 28px;

$h1-size: 32;

$h2-size: 28;

$h3-size: 24;

$h4-size: 22;

$h5-size: 18;

$h6-size: 16;

$h1-size-pony: 52;

$h2-size-pony: 40;

$h3-size-pony: 34;

$h4-size-pony: 32;

$h5-size-pony: 28;

$h6-size-pony: 26;

$mainFont: Raleway, sans-serif;

$displayFont: Raleway, sans-serif;

$ponyDisplayFont: Sue Ellen Francisco, sans-serif;

$iconFont: icomoon, sans-serif;

// Project colors

$primary: #b03d2e;
$primaryBeratung: #F68629;
$primarySpiegelpferd: #ba0000;
$primaryPonyseidank: #233870;
$secondaryBeratung: #662166;
$thirdBeratung: #E63B53;
$lerncoaching: #4cb06b;
$trainer: #fdb157;
$a-z: #ff6b6d;
$secondary: #EDE8DD;
$light: #fff;
$medium: #717171;
$dark: #171717;
$border: #ddd;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$pageWrapMaxWidth: 3000px;

$time: 300ms;

$longTime: 600ms;

$baseRadius: 10px;

$baseShadow: 2px 2px 10px rgba(#000, .2);

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	login: "\e911", 
	horse: "\e906", 
	quotes: "\e910", 
	mail: "\e919", 
	pdf: "\eadf", 
	link: "\e908", 
	download: "\e9c7", 
	arrow-up: "\e90d", 
	fax: "\e90e", 
	mobil: "\e90f", 
	swiper: "\e90c", 
	play: "\e90b", 
	facebook: "\e90a", 
	instagram: "\e909", 
	arrow-down: "\e905", 
	clock: "\e907", 
	phone: "\e900", 
	angle-right: "\e903", 
	map: "\e904", 
	arrow-right: "\e902", 
	post: "\e901", 
	plus: "\f067", 
	minus: "\f068", 
	times: "\f00d", 
	exclamation: "\f06a", 
);

$bgIcons: (
	blackStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJTdGVybl9taXRfNV9aYWNrZW5fMSIgZGF0YS1uYW1lPSJTdGVybiBtaXQgNSBaYWNrZW4gMSIgY2xhc3M9ImNscy0xIiBkPSJNMzItLjA0NEw0Mi41NDYsMjAuMDgyLDY0LDI0LjQxOCw0OS4wNjQsNDEuMTkzLDUxLjc3NSw2NCwzMiw1NC4yMzksMTIuMjI1LDY0bDIuNzEtMjIuODA2TDAsMjQuNDE4bDIxLjQ0OS00LjMzNkwzMi0uMDQ0Ii8+Cjwvc3ZnPgo=", 
	blackTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJSZWNodGVja18xIiBkYXRhLW5hbWU9IlJlY2h0ZWNrIDEiIGNsYXNzPSJjbHMtMSIgZD0iTTUxLjc1MSw2LjVMNTcuNSwxMi4yNDksMTIuMjQ5LDU3LjUsNi41LDUxLjc1MSw1MS43NTEsNi41Ii8+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNNTcuNSw1MS43NTFMNTEuNzUxLDU3LjUsNi41LDEyLjI0OSwxMi4yNDksNi41LDU3LjUsNTEuNzUxIi8+Cjwvc3ZnPgo=", 
	blackCheck: "data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU2IDUxNS41NTYiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTE1LjU1NiA1MTUuNTU2IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDI3NC4yMjYgMTc2LjU0OSAxNzYuODg2IDMzOS4wMDctMzM4LjY3Mi00OC42Ny00Ny45OTctMjkwLjMzNyAyOTAtMTI4LjU1My0xMjguNTUyeiIvPjwvc3ZnPg==", 
	whiteStar: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlN0ZXJuX21pdF81X1phY2tlbl8xIiBkYXRhLW5hbWU9IlN0ZXJuIG1pdCA1IFphY2tlbiAxIiBjbGFzcz0iY2xzLTEiIGQ9Ik0zMi0uMDQ0TDQyLjU0NiwyMC4wODIsNjQsMjQuNDE4LDQ5LjA2NCw0MS4xOTMsNTEuNzc1LDY0LDMyLDU0LjIzOSwxMi4yMjUsNjRsMi43MS0yMi44MDZMMCwyNC40MThsMjEuNDQ5LTQuMzM2TDMyLS4wNDQiLz4KPC9zdmc+Cg==", 
	whiteTimes: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzEiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSIgY2xhc3M9ImNscy0xIiBkPSJNNTEuNzUxLDYuNUw1Ny41LDEyLjI0OSwxMi4yNDksNTcuNSw2LjUsNTEuNzUxLDUxLjc1MSw2LjUiLz4KICA8cGF0aCBpZD0iUmVjaHRlY2tfMV9Lb3BpZSIgZGF0YS1uYW1lPSJSZWNodGVjayAxIEtvcGllIiBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny41LDUxLjc1MUw1MS43NTEsNTcuNSw2LjUsMTIuMjQ5LDEyLjI0OSw2LjUsNTcuNSw1MS43NTEiLz4KPC9zdmc+Cg==", 
	whiteCheck: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI2NCIgdmlld0JveD0iMCAwIDY0IDY0Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZmZmOwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9IlJlY2h0ZWNrXzFfS29waWUiIGRhdGEtbmFtZT0iUmVjaHRlY2sgMSBLb3BpZSIgY2xhc3M9ImNscy0xIiBkPSJNMjIuNiw1NS4zMkw2NC4zMiwxMy42LDU4LjY2Myw3Ljk0NCwyMi42LDQ0LjAwNiw2LjMzNywyNy43NDMsMC42OCwzMy40WiIvPgo8L3N2Zz4K", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	mini: (
		width: em(360px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	full: (
		width: em(1620px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	extend: (
		width: em(1920px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	extendmax: (
		width: em(2500px, $bpContext), 
		cols: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		evenly: (justify-content:space-evenly),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
);


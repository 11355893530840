@include fontFaceV2(
    $fontName: "icomoon",
    $fileName: "icomoon",
    $types: ("woff"),
    $style: normal
);

@include fontFaceV2(
    $fontName: "Raleway",
    $fileName: "raleway-v27-latin",
    $types: ("woff2","woff"),
    $weights: ("300", "700", "900", "300|italic", "700|italic", "900|italic")
);

@include fontFaceV2(
    $fontName: "Sue Ellen Francisco",
    $fileName: "sue-ellen-francisco-v16-latin",
    $types: ("woff2","woff"),
    $weights: ("400")
);



// HEADER
//////////////////////////////

#header {
	position: relative;
	height: rem(450px);
	@include responsiveImage('index/bg-beratung.jpg', (tiny, medium, large), false);
	background: {
		size: cover;
		position: center;
		repeat: no-repeat;
	}
	transition: height $time, margin $time;

	@include breakpoint(giant) {
		height: rem(550px);
	}
	@include breakpoint(full) {
		height: rem(700px);
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: .8;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba($dark,1) 75%);
		transition: opacity $time;

		@include breakpoint(medium) {
			opacity: .5;
		}
	}
	body.index & {
		display: none;
	}
	body:not(.psychosozialeBeratungIndex):not(.spiegelpferdIndex):not(.ponyseidankIndex) & {
		height: 300px;

		.claimHolder {
			display: none;	
		}
		.brandingHolder {
			height: 100%;

			.branding {
				display: flex;
				justify-content: center;
				transition: transform $time;

				&:hover {
					transform: scale(1.1);
				}
				svg {
					max-height: 100px;
					max-width: 100px;
				}
			}
		}
	}
	body.spiegelpferd &, body.ponyseidank & {
		margin-top: rem(50px);

		@include breakpoint(giant) {
			margin-top: rem(103px);
		}
		@include breakpoint(huge) {
			margin-top: rem(117px);
		}
		@include breakpoint(full) {
			margin-top: rem(138px);
		}
	}
	body.psychosozialeBeratung & {
		@include responsiveImage('psychologische-beratung/bg-header.jpg', (tiny, medium, giant, full), false);
		margin-top: rem(50px);
		
		@include breakpoint(giant) {
			margin-top: rem(112px);
		}
		@include breakpoint(huge) {
			margin-top: rem(128px);
		}
		@include breakpoint(full) {
			margin-top: rem(128px);
		}
	}
	body.spiegelpferd & {
		@include responsiveImage('spiegelpferd/bg-header.jpg', (tiny, medium, giant, full), false);
		margin-top: rem(50px);
		
		@include breakpoint(giant) {
			margin-top: rem(103px);
		}
		@include breakpoint(huge) {
			margin-top: rem(103px);
		}
	}
	body.ponyseidank & {
		@include responsiveImage('pony-sei-dank/bg-header.jpg', (tiny, medium, giant, full), false);
		background: {
			position: center;
		}
		margin-top: rem(50px);

		@include breakpoint(giant) {
			margin-top: rem(103px);
		}
		@include breakpoint(huge) {
			margin-top: rem(117px);
		}
		@include breakpoint(full) {
			margin-top: rem(138px);
		}
	}

	.claimHolder {
		position: relative;
		z-index: 50px;
		display: flex;
		align-items: flex-end;
		height: 100%;
		padding-bottom: rem(50px);
		transition: padding $time;

		@include breakpoint(medium) {
			padding-bottom: rem(100px);
		}
		@include breakpoint(huge) {
			padding-bottom: rem(150px);
		}
		.claim {
			color: $light;
			font-size: rem(20px);
			line-height: rem(25px);
			transition: font $time, line $time;

			@include breakpoint(large) {
				font-size: rem(30px);
				line-height: rem(40px);
			}
		}
		body.ponyseidank & {
			font-family: $ponyDisplayFont;

			@include breakpoint(huge) {
				padding-bottom: rem(170px);
			}
			.claim {
				color: $light;
				font-size: rem(30px);
				line-height: rem(40px);
				transition: font $time, line $time;
	
				@include breakpoint(large) {
					font-size: rem(40px);
					line-height: rem(55px);
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		border-radius: $baseRadius;
		margin-bottom:rem($baseGap*2);
	}
	.bgImg {
		position: relative;
		height: 100%;
		min-height: 250px;
		border-radius: $baseRadius;
		margin-bottom: rem($baseGap*2);

		@include breakpoint(small) {
			min-height: 350px;
		}
		@include breakpoint(medium) {
			margin-bottom: 0;
		}
		a {
			&:not(.ewcms_edit_link) {
				display: block!important;
				height: 100%;
				width: 100%;
				overflow: hidden;
				border-radius: $baseRadius;
				margin-bottom: 0!important;

				img {
					height: 100%;
					width: 100%;
					object: {
						fit: cover;
						position: center;
					}
					max-width: inherit;
					margin-bottom: 0;
				}
			}
		}
		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			display: block;
			top: 10px;
			left: 10px;
			height: 100%;
			width: 100%;
			background: rgba($primary, .5);
			border-radius: $baseRadius;

			@include breakpoint(full) {
				top: 20px;
				left: 20px;
			}
			body.psychosozialeBeratung & {
				background: rgba($primaryBeratung, .5);
			}
			body.spiegelpferd & {
				background: rgba($primarySpiegelpferd, .5);
			}
			body.ponyseidank & {
				background: rgba($primaryPonyseidank, .5);
			}
			.left & {
				right: 10px;

				@include breakpoint(full) {
					right: 20px;
				}
			}
		}
	}
	.higlightBox {
		background: rgba($alert, .2);
		padding: rem(30px) rem(20px);

		p {
			text-align: center;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	// Vorschaltseite
	.vorschauHolder {
		.bigHolder {
			position: relative;
			height: 100%;
			min-height: 450px;
			max-height: 680px;
			background: {
				size: cover;
				position: center;
				repeat: no-repeat;
			}
			overflow: hidden;
			
			@include breakpoint(large) {
				height: 490px;
			}
			@include breakpoint(huge) {
				height: 580px;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				transition: background $longTime;
			}
			&.beratung {
				@include responsiveImage('index/bg-beratung.jpg', (tiny, medium, large), false);
				padding-right: rem(20px);
				transition: padding $time;

				@include breakpoint(medium) {
					padding-right: rem(50px);
				}
				@include breakpoint(large) {
					padding-right: rem(80px);
				}
				@include breakpoint(huge) {
					padding-right: rem(120px);
				}
				&:hover {
					&:before {
						background: rgba($primaryBeratung, .7);
					}
				}
				&:before {
					background: rgba($primaryBeratung, .5);
				}
				.inner {
					align-items: flex-end;

					.linkBtn {
						background: $primaryBeratung;
					}
				}
			}
			&.spiegelpferd {
				@include responsiveImage('index/bg-spiegelpferd.jpg', (tiny, medium, large), false);
				padding-left: rem(20px);
				transition: padding $time;
				
				@include breakpoint(medium) {
					padding-left: rem(50px);
				}
				@include breakpoint(large) {
					padding-left: rem(80px);
				}
				@include breakpoint(huge) {
					padding-left: rem(120px);
				}
				&:hover {
					&:before {
						background: rgba($primarySpiegelpferd, .6);
					}
				}
				&:before {
					background: rgba($primarySpiegelpferd, .4);
				}
				.inner {
					align-items: flex-start;

					.branding {
						img {
							max-height: 70px;

							@include breakpoint(giant) {
								max-height: 100px;
							}
						}
					}
					.linkBtn {
						background: $primarySpiegelpferd;
					}
				}
			}
			&.pony {
				@include responsiveImage('index/bg-ponyseidank.jpg', (tiny, medium, large), false);
				padding-right: rem(20px);
				transition: padding $time;

				@include breakpoint(medium) {
					padding-right: rem(0);
				}
				
				&:hover {
					&:before {
						background: rgba($primaryPonyseidank, .7);
					}
				}
				&:before {
					background: rgba($primaryPonyseidank, .5);
				}
				.inner {
					align-items: flex-end;

					@include breakpoint(medium) {
						align-items: center;
					}
					.linkBtn {
						background: $primaryPonyseidank;
					}
				}
			}

			.inner {
				position: relative;
				z-index: 50;
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;

				.branding {
					margin-bottom: 30px;
					transition: margin $time;

					@include breakpoint(huge) {
						margin-bottom: 50px;
					}
					img {
						max-height: 120px;
						margin-bottom: 0;
						transition: max-height $time;
					}
				}
				.name {
					color: rgba($light, .5);
					font-size: rem(22px);
					font-weight: 900;
					text-transform: uppercase;
					margin-bottom: 45px;
					transition: font $time, margin $time;

					@include breakpoint(tiny) {
						font-size: rem(30px);
					}
					@include breakpoint(small) {
						font-size: rem(40px);
					}
					@include breakpoint(medium) {
						font-size: rem(20px);
					}
					@include breakpoint(large) {
						font-size: rem(25px);
					}
					@include breakpoint(giant) {
						font-size: rem(30px);
					}
					@include breakpoint(huge) {
						font-size: rem(35px);
						margin-bottom: 80px;
					}
					@include breakpoint(full) {
						font-size: rem(40px);
					}
					@include breakpoint(extend) {
						font-size: rem(45px);
					}

					p {
						margin-bottom: 0;
					}
				}
				.linkBtn {
					display: inline-block;
					border-radius: 50%;
					color: $light;
					line-height: 0;
					padding: 20px 24px;
					transition: background $time, border $time;

					&:hover {
						border: {
							width: 1px;
							style: solid;
							color: $light;
						}
						background: rgba($dark, .5);
					}
				}
			}
		}
	} // end Vorschaltseite
	
	body.psychosozialeBeratung & {
		.teaserHolder {
			.teaserItem {
				display: block;
				width: 100%;
				font-size: rem(20px);
				text-align: center;
				color: $dark;
				background: $light;
				border: {
					width: 1px;
					style: solid;
					color: $primaryBeratung;
					radius: $baseRadius;
				}
				padding: rem(20px) 0;
				margin-bottom: rem($baseGap);
				transition: font $time, color $time, background $time, padding $time;
	
				@include breakpoint(medium) {
					margin-bottom: 0;
				}
				@include breakpoint(huge) {
					font-size: rem(25px);
					padding: rem(30px) 0;
				}
				&:hover {
					color: $light;
					background: $primaryBeratung;
				}
			}
		}
	}

	body.spiegelpferd & {
		.teaserHolder {
			.teaserItem {
				display: block;
				width: 100%;
				font-size: rem(20px);
				text-align: center;
				color: $dark;
				background: $light;
				border: {
					width: 1px;
					style: solid;
					color: $primarySpiegelpferd;
					radius: $baseRadius;
				}
				padding: rem(20px) 0;
				margin-bottom: rem($baseGap);
				transition: font $time, color $time, background $time, padding $time;
	
				@include breakpoint(medium) {
					margin-bottom: 0;
				}
				@include breakpoint(huge) {
					font-size: rem(25px);
					padding: rem(30px) 0;
				}
				&:hover {
					color: $light;
					background: $primarySpiegelpferd;
				}
			}
		}
	}

	// Psychologische Beratung
	body.psychosozialeBeratung & {
		.bewertungsHolder {
			h3 {
				color: $dark;
				text-align: center;
				margin-bottom: calc(#{$baseLineHeight} + 14px);
				transition: margin $time;

				@include breakpoint(large) {
					margin-bottom: calc(#{$baseLineHeight} + 24px);
				}
			}
			.bewertungsItem {
				position: relative;
				display: block;
				background: #f6f6f6;
				border: {
					radius: $baseRadius;
				}
				box-shadow: $baseShadow;
				padding: rem(30px);
				margin-bottom: rem($baseGap*2);
				transition: padding $time, margin $time;

				@include breakpoint(large) {
					padding: rem(50px);
				}
				@include breakpoint(giant) {
					margin-bottom: rem(60px);
				}
				&:before {
					position: absolute;
					top: -10px;
					left: 30px;
					height: 40px;
					content: map-get($iconMap, quotes);
					font-family: $iconFont;
					font-size: rem(40px);
					color: $primaryBeratung;
					transition: top $time, left $time, height $time, font $time;

					@include breakpoint(large) {
						top: -8px;
						left: 50px;
						height: 60px;
						font-size: rem(60px);
					}
				}
				.inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%;
					min-height: 250px;
					transition: min-height $time;

					@include breakpoint(tiny) {
						min-height: 200px;
					}
					@include breakpoint(medium) {
						min-height: 250px;
					}
					@include breakpoint(large) {
						min-height: 200px;
					}
					.title {
						font-size: rem(20px);
						font-weight: 700;
						text-align: right;
						color: lighten($dark, 60%);
						transition: font $time;
	
						@include breakpoint(large) {
							font-size: rem(25px);
						}
					}
					.txt {
						p {
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
			.badge {
				position: absolute;
				bottom: 0px;
				right: rem($baseGap);
				font-size: rem(20px);
				color: $light;
				background: $primaryBeratung;
				border-radius: 50%;
				padding: rem(35px) rem(20px);
				transform: rotate(15deg);
				transition: bottom $time, right $time, font $time, padding $time;

				@include breakpoint(small) {
					bottom: 10px;
				}
				@include breakpoint(medium) {
					padding: rem(25px) rem(10px);
				}
				@include breakpoint(large) {
					bottom: 20px;
					padding: rem(37px) rem(20px);
				}
				@include breakpoint(huge) {
					right: rem(60px);
					bottom: 20px;
					font-size: rem(25px);
					padding: rem(45px) rem(20px);
				}
				@include breakpoint(full) {
					right: rem(90px);
					bottom: 150px;
				}
			}
			.subText {
				font-size: rem(14px);
			}
		} // end bewertungsHolder
		.angebotHolder {
			.angebotItem {
				position: relative;
				display: block;
				background: $light;
				border-radius: $baseRadius;
				box-shadow: $baseShadow;
				padding: rem($baseGap);
				margin-bottom: rem($baseGap*2);
				transition: padding $time, margin $time;

				@include breakpoint(large) {
					padding: rem($baseGap*2);
				}
				@include breakpoint(giant) {
					margin-bottom: rem(60px);
				}
				.headline {
					@extend h3;
					text-align: center;

					span {
						font-size: rem(16px);
					}
				}
				.smallTitle {
					display: block;
					font-size: rem(20px);
					font-weight: 700;
					margin-bottom: rem($baseGap);
				}
				.collapser {
					display: block;
					width: 100%;

					&.active+.collapserContent {
						margin-top: rem($baseGap*1.5);
					}
				}
			}
		} // end angebotHolder
		.blogHolder {
			.blogItem {
				display: block;
				min-height: 400px;
				background: #f6f6f6;
				box-shadow: $baseShadow;
				border-radius: $baseRadius;
				padding: rem(20px);
				margin-bottom: rem($baseGap);
				transition: margin $time;

				@include breakpoint(medium) {
					padding: rem($baseGap);
					margin-bottom: rem($baseGap*2);
				}
				.inner {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-direction: column;
					height: 100%;

                    .title {
						text-align: center;
					}
					.modalImage {
						height: 250px;
						width: 100%;
						margin-bottom: rem(10px);

						a {
							&:not(.ewcms_edit_link) {
								display: block!important;
								height: 100%;
								width: 100%;
								overflow: hidden;
								border-radius: $baseRadius;
								margin-bottom: 0!important;
				
								img {
									height: 100%;
									width: 100%;
									object: {
										fit: cover;
										position: center;
									}
									max-width: inherit;
									margin-bottom: 0;
								}
							}
						}
					}
					.txt {
						p {
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		} // end blogHolder
	} // end Psychologische Beratung
	body.spiegelpferd & {
		.impressionenHolder {
			h3 {
				color: $dark;
				text-align: center;
				margin-bottom: rem($baseLineHeight);
				transition: margin $time;
			}
			&.modalGallery {
				.modalImage {
					overflow: hidden;
		
					a {
						&:not(.ewcms_edit_link) {
							display: block;
							height: 460px;
							border-radius: $baseRadius;
						
							img {
								height: 100%;
								width: 100%;
								object: {
									fit: cover;
									position: center;
								}
								max-width: inherit;
								box-shadow: $baseShadow;
							}
						}
					}
				}
			}
			.marginTop {
				@include breakpoint(large) {
					margin-top: rem(50px);
				}
			}
		} // end impressionenHolder
		.angebotHolder {
			.angebotItem {
				position: relative;
				display: block;
				background: $light;
				border-radius: $baseRadius;
				box-shadow: $baseShadow;
				padding: rem($baseGap);
				margin-bottom: rem($baseGap*2);
				transition: padding $time, margin $time;

				@include breakpoint(large) {
					padding: rem($baseGap*2);
				}
				@include breakpoint(giant) {
					margin-bottom: rem(60px);
				}
				.headline {
					@extend h3;
					text-align: center;

					span {
						font-size: rem(16px);
					}
				}
				.smallTitle {
					display: block;
					font-size: rem(20px);
					font-weight: 700;
					margin-bottom: rem($baseGap);
				}
				.collapser {
					display: block;
					width: 100%;

					&.active+.collapserContent {
						margin-top: rem($baseGap*1.5);
					}
				}
			}
		} // end angebotHolder
		.aktuellesHolder {
			.aktuellesItem {
				box-shadow: $baseShadow;
				border-radius: $baseRadius;
				padding: rem(30px) rem(20px);
				margin-bottom: rem($baseGap);
				transition: margin $time;
				
				@include breakpoint(medium) {
					padding: rem($baseGap*2);
					margin-bottom: rem($baseGap*2);
				}
				.collapser {
					background: inherit;
					padding: rem($baseGap) 0;
					transition: border $time;

					&:not(.active) {
						border-bottom: 1px solid rgba($medium, .2);
					}
					&+.collapserContent {
						padding-left: rem($baseGap);
					}
				}
			}
		} // end aktuellesHolder
		.teamHolder {
			.modalGallery {
				.modalImage {
					a {
						display: block;
						height: 350px;
						overflow: hidden;

						img {
							height: 100%;
							width: 100%;
							object: {
								fit: cover;
								position: center;
							}
						}
					}
				}
			}
		} // end teamHolder
	} // end Spiegelpferd
	body.ponyseidank & {
		.teaserHolder {
			margin-top: rem(50px);

			.teaserItem {
				display: block;
				background: $light;
				border: {
					width: 1px;
					style: solid;
					radius: $baseRadius;
				}
				overflow: hidden;
				text-decoration: none;
				margin-bottom: rem($baseGap);
				transition: background $time;

				@include breakpoint(medium) {
					height: 100%;
					margin-bottom: 0;
				}
				.inner {
					.imgHolder {
						max-height: 200px;
						overflow: hidden;
	
						img {
							border: {
								bottom-right-radius: 0;
								bottom-left-radius: 0;
							}
							margin-bottom: 0;
						}
					}
					p {
						font-family: $ponyDisplayFont;
						font-size: 30px;
						line-height: rem(55px);
						text-align: center;
						padding: rem(30px) 0;
						margin-bottom: 0;
						transition: font $time, line $time, color $time, padding $time;

						@include breakpoint(large) {
							font-size: rem(35px);
							line-height: rem(60px);
							padding: rem(40px) 0;
						}
						span {
							display: block;
							font-family: $mainFont;
							font-size: rem(16px);
							line-height: rem(18px);
							color: $medium;
							transition: color $time;

							@include breakpoint(large) {
								line-height: rem(18px);
							}
						}
					}
				}
				&.lerncoaching {
					border-color: $lerncoaching;
					
					.inner {
						p {
							color: $lerncoaching;
						}
					}
				}
				&.trainer {
					border-color: $trainer;

					.inner {
						p {
							color: $trainer;
						}
					}
				}
				&.aBisz {
					border-color: $a-z;

					.inner {
						p {
							color: $a-z;
						}
					}
				}
				&:hover {
					&.lerncoaching, &.trainer, &.aBisz {
						p {
							color: $light;
	
							span {
								color: $light;
							}
						}
					}
					&.lerncoaching {
						background: $lerncoaching;
						
					}
					&.trainer {
						background: $trainer;
					}
					&.aBisz {
						background: $a-z;
					}
				}
			}
		}
		.preiseTabelle {
			tr {
				border-bottom: 0.0625rem solid rgba($dark, .2);

				th {
					font-family: $ponyDisplayFont;
					font-size: 25px;
					color: $primaryPonyseidank;
				}
				td {
					strong {
						display: block;
					}
				}
			}
		}
		.trainerHolder {
			.trainerItem {
				background: $light;
				border-radius: $baseRadius;
				box-shadow: $baseShadow;
				padding: rem(30px) rem(20px);
				margin-bottom: rem($baseGap);
				transition: margin $time;

				@include breakpoint(large) {
					padding: rem($baseGap*2);
					margin-bottom: rem($baseGap*2);
				}
				.inner {
					.modalImage {
						height: 350px;
						overflow: hidden;
						margin-bottom: rem($baseGap);
						transition: height $time;

						@include breakpoint(huge) {
							height: 450px;
							margin-bottom: rem($baseGap*2);
						}
						a {
							display: block;
							height: 100%;

							img {
								height: 100%;
								width: 100%;
								object:{
									fit: cover;
									position: top center;
								}
								margin-bottom: 0;
							}
						}
					}
					.trainerName {
						line-height: 130%;
						margin-bottom: rem(20px);

						span {
							font-size: rem(16px);
						}
					}
					.txt {
						p {
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
		.fragenHolder {
			.collapser {
				font-size: rem(25px);
        		line-height: rem(35px);

				@include breakpoint(large) {
					font-size: rem(35px);
        			line-height: rem(50px);
				}
			}
		}
		.newsHolder {
			.newsItem {
				background: $light;
				border-radius: $baseRadius;
				box-shadow: $baseShadow;
				padding: rem(30px) rem(20px);
				margin-bottom: rem($baseGap);
				transition: margin $time;

				@include breakpoint(large) {
					padding: rem($baseGap*2);
					margin-bottom: rem($baseGap*2);
				}
				.inner {
					.newsName {
						line-height: 130%;
						margin-bottom: rem(20px);

						span {
							font-size: rem(16px);
						}
					}
					.txt {
						p {
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}



	// end Ponyseidank

	// Übersichtseite des Login-Bereichs
	body.uebersicht & {
		.downloadHolder {
			.downloadItem {
				background: $light;
				border-radius: $baseRadius;
				box-shadow: $baseShadow;
				padding: rem(30px) rem(20px);
				margin-bottom: rem($baseGap);
				transition: margin $time;

				@include breakpoint(large) {
					padding: rem($baseGap*2);
					margin-bottom: rem($baseGap*2);
				}
				.btn {
					width: 100%;
				}
			}
		}
	} // end Login-Bereich
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	// Vorschaltseite
	body.index &, body.newsletter &, body.anfahrt &, body.impressum &, body.datenschutzerklaerung & {
		padding: rem(30px) 0;
		transition: padding $time;

		@include breakpoint(huge) {
			padding: rem(50px) 0;
		}
	}
	.contacts {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include breakpoint(giant) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-evenly;
			padding: rem(20px) 0;
		}
		@include breakpoint(huge) {
			padding: 0;
		}
		@include breakpoint(full) {
			align-items: flex-start;
			justify-content: flex-start;
		}
		li {
			width: 100%;
			text-align: center;
			padding: rem(12px) 0;

			&:not(:last-child) {
				border-bottom: 1px solid rgba($medium, .2);
			}
			@include breakpoint(giant) {
				width: auto;
				text-align: inherit;
				padding: 0;

				&:not(:last-child) {
					border-bottom: none;
					margin-right: rem(50px);
				}
			}
			a {
				color: $dark;
				text-decoration: none;

				&:hover {
					color: $primary;
				}
			}
			address {
				display: inline-block;
				padding: 0;

				&:before {
					display: none;
				}
			}
			.icon-phone, .icon-mail, .icon-map {
				&:before {
					color: $light;
					background: $primaryPonyseidank;
					border-radius: 50%;
					padding: rem(10px);
					margin-right: rem(10px);
				}
			}
			.icon-mail {
				&:before {
					font-size: rem(12px);
					padding: rem(12px) rem(10px);
				}
			}
		}
	} // end Vorschaltseite
	.googleMaps {
		height: rem(350px);
		padding-bottom: inherit;
		transition: height $time;

		@include breakpoint(large) {
			height: rem(400px);
		}
	}
	.footerCtn {
		padding: rem(20px) 0;
		transition: padding $time;

		@include breakpoint(medium) {
			padding: rem(30px) 0;
		}
		@include breakpoint(large) {
			padding: rem(40px) 0;
		}
		@include breakpoint(huge) {
			padding: rem(50px) 0;
		}
	}
	address {
		@include breakpoint(huge, max) {
			padding: rem(12px) 0;		
			text-align: center;
		}
		&:before {
			content: map-get($iconMap, map);
			font-family: $iconFont;
			font-size: rem(14px);
			color: $primary;
			margin-right: rem(10px);

			body.psychosozialeBeratung & {
				color: $primaryBeratung;
			}
			body.spiegelpferd & {
				color: $primarySpiegelpferd;
			}
			body.ponyseidank & {
				color: $primaryPonyseidank;
			}
		}
		strong {
			color: $primary;

			body.psychosozialeBeratung & {
				color: $primaryBeratung;
			}
			body.spiegelpferd & {
				color: $primarySpiegelpferd;
			}
			body.ponyseidank & {
				color: $primaryPonyseidank;
			}
		}
	}
}

.btn_color_coaching, .backToTop, .btn{
	body.psychosozialeBeratung & {
	background: $primaryBeratung !important;
	}
}

h1, h2, h3, h4, h5{	
	body.psychosozialeBeratung & {
		color: $secondaryBeratung;
	}
}

a{	
	body.psychosozialeBeratung & {
		color: $thirdBeratung;
	}
}

.margin-bottom{
	margin-bottom: rem(30px);
}

.svgIcons.beratung path{
	fill: $primaryBeratung;
}
.branding.beratung path{
	fill: $primaryBeratung;
}

.fixedContacts {
	z-index: 89 !important;
}

.logos-section {
	background-color: #f2f2f2;;
	text-align: center;
	padding: 20px 0;
	
    .title {
		padding-top: 20px;
		// Weitere Stilisierung für die Überschrift hier einfügen, falls benötigt
	  }
  
	.logos {
	  display: flex;
	  justify-content: space-around;
	  align-items: center;
	  flex-wrap: wrap;
  
	  img {
		width: 120px; /* Breite jedes Logos */
		height: auto;
		padding: 10px;
		margin-bottom: 0;
		opacity: 0.4;
		// Fügen Sie hier weitere Stile hinzu, um den Abstand oder die Ausrichtung der Logos anzupassen
	  }
	}
  }
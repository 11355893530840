* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {}

::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
  overflow-anchor: none;

  &:not(.javascript) * {
    transition: none !important;
  }
}

#pageWrap {
  max-width: $pageWrapMaxWidth;
  margin: 0 auto;
  overflow: hidden;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style: normal;
}

// SECTION
/////////////////////////////
.section {
  @include section;
}

.sectionFull {
  @include sectionFull;
}

.sectionSmall {
  @include sectionSmall;
}

.sectionSmallFull {
  @include sectionSmallFull;
}

.section,
.sectionFull,
.sectionSmall,
.sectionSmallFull {
  position: relative;

  &.sectionColor {
    background: darken($secondary, 5%);
    color: $light;

    a {
      color: $light;
      transition: $time opacity;

      &:hover {
        opacity: .6;
      }
    }
  }

  &.sectionMedium {
    background: $medium;
  }

  &.noGapTop {
    padding-top: 0;
  }

  &.noGapBottom {
    padding-bottom: 0;
  }
}

/**
* Headlines
*/
//SETTINGS: Fontsize Start-Value at 320px
$h1-small: 30;
$h2-small: 28;
$h3-small: 24;
$h4-small: 22;
$h5-small: 20;
$h6-small: 18;

$h1-small-pony: 45;
$h2-small-pony: 33;
$h3-small-pony: 39;
$h4-small-pony: 37;
$h5-small-pony: 30;
$h6-small-pony: 18;

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  margin-bottom: 1em;
  line-height: 130%;
  font-family: $displayFont;
  color: $primary;
  font-weight: 300;

  body.psychologische-beratung & {
    color: $primaryBeratung;

    span {
      color: $medium;
    }
  }
  body.spiegelpferd & {
    color: $primarySpiegelpferd;

    span {
      color: $medium;
    }
  }
  body.ponyseidank & {
    font-family: $ponyDisplayFont;
    line-height: 170%;
    color: $primaryPonyseidank;

    span {
      font-family: $mainFont;
      color: $medium;
      line-height: rem(20px);
      font-size: rem(22px)!important;
    }
  }
  span {
    display: block;
    @extend .h5;
    color: $medium;
  }
  &.line {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: 0;
      width: 100px;
      height: 1px;
      background: rgba($primary, 0.5);
      transition: width $time;

      body.psychologische-beratung & {
        background: rgba($primaryBeratung, 0.5);
      }
      body.spiegelpferd & {
        background: rgba($primarySpiegelpferd, 0.5);
      }
      body.ponyseidank & {
        background: rgba($primaryPonyseidank, 0.5);
      }
      @include breakpoint(large) {
        width: 200px;
      }
    }
  }
}

h1,
.h1 {
  font-size: calc(#{$h1-small*1px} + (#{$h1-size} - #{$h1-small}) * ((100vw - 320px) / (1600 - 320)));

  @include breakpoint(full) {
    font-size: rem($h1-size*1px);
  }
  body.ponyseidank & {
    font-size: calc(#{$h1-small-pony*1px} + (#{$h1-size-pony} - #{$h1-small-pony}) * ((100vw - 320px) / (1600 - 320)));

    @include breakpoint(full) {
      font-size: rem($h1-size-pony*1px);
    }
  }
}

h2,
.h2 {
  font-size: calc(#{$h2-small*1px} + (#{$h2-size} - #{$h2-small}) * ((100vw - 320px) / (1600 - 320)));

  @include breakpoint(full) {
    font-size: rem($h2-size*1px);
  }
  body.ponyseidank & {
    font-size: calc(#{$h2-small-pony*1px} + (#{$h2-size-pony} - #{$h2-small-pony}) * ((100vw - 320px) / (1600 - 320)));

    @include breakpoint(full) {
      font-size: rem($h2-size-pony*1px);
    }
  }
}

h3,
.h3 {
  font-size: calc(#{$h3-small*1px} + (#{$h3-size} - #{$h3-small}) * ((100vw - 320px) / (1600 - 320)));

  @include breakpoint(full) {
    font-size: rem($h3-size*1px);
  }
  body.ponyseidank & {
    font-size: calc(#{$h3-small-pony*1px} + (#{$h3-size-pony} - #{$h3-small-pony}) * ((100vw - 320px) / (1600 - 320)));

    @include breakpoint(full) {
      font-size: rem($h3-size-pony*1px);
    }
  }
}

h4,
.h4 {
  font-size: calc(#{$h4-small*1px} + (#{$h4-size} - #{$h4-small}) * ((100vw - 320px) / (1600 - 320)));

  @include breakpoint(full) {
    font-size: rem($h4-size*1px);
  }
  body.ponyseidank & {
    font-size: calc(#{$h4-small-pony*1px} + (#{$h4-size-pony} - #{$h4-small-pony}) * ((100vw - 320px) / (1600 - 320)));

    @include breakpoint(full) {
      font-size: rem($h4-size-pony*1px);
    }
  }
}

h5,
.h5 {
  font-size: calc(#{$h5-small*1px} + (#{$h5-size} - #{$h5-small}) * ((100vw - 320px) / (1600 - 320)));

  @include breakpoint(full) {
    font-size: rem($h5-size*1px);
  }
  body.ponyseidank & {
    font-size: calc(#{$h5-small-pony*1px} + (#{$h5-size-pony} - #{$h5-small-pony}) * ((100vw - 320px) / (1600 - 320)));

    @include breakpoint(full) {
      font-size: rem($h5-size-pony*1px);
    }
  }
}

h6,
.h6 {
  font-size: calc(#{$h6-small*1px} + (#{$h6-size} - #{$h6-small}) * ((100vw - 320px) / (1600 - 320)));

  @include breakpoint(full) {
    font-size: rem($h6-size*1px);
  }
  body.ponyseidank & {
    font-size: calc(#{$h6-small-pony*1px} + (#{$h6-size-pony} - #{$h6-small-pony}) * ((100vw - 320px) / (1600 - 320)));

    @include breakpoint(full) {
      font-size: rem($h6-size-pony*1px);
    }
  }
}

/**
 * Links
 */
a {
  color: $primary;
  word-wrap: break-word;
  text-decoration: none;

  body.psychologische-beratung & {
    color: $primaryBeratung;
  }
  body.spiegelpferd & {
    color: $primarySpiegelpferd;

    &:hover {
      color: lighten($dark, 10%);
    }
  
    &:focus {
      color: lighten($dark, 10%);
    }
  
    &:active {
      color: lighten($dark, 10%);
    }
  }
  body.ponyseidank & {
    color: $primaryPonyseidank;

    &:hover {
      color: lighten($dark, 10%);
    }
  
    &:focus {
      color: lighten($dark, 10%);
    }
  
    &:active {
      color: lighten($dark, 10%);
    }
  }

  &:hover {
    color: lighten($dark, 10%);
  }

  &:focus {
    color: lighten($dark, 10%);
  }

  &:active {
    color: lighten($dark, 10%);
  }

  img {
    border: none;
  }
  &.btn {
    color: $light!important;
  }

  &[href^="tel"] {
    color: inherit;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: rgba($medium, .1);
  border: none;
  clear: both;
  width: 70%;
  height: rem(1px);
  margin: rem(20px) auto;
  flex-basis: 100%;
  transition: margin $time;

  @include breakpoint(medium) {
    margin: rem(30px) auto;
  }
  @include breakpoint(giant) {
    margin: rem(40px) auto;
  }
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(20px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-angle-right;
        font-size: rem(13px);
        text-indent: rem(-20px);
        color: $primary;

        body.psychologische-beratung & {
          color: $primaryBeratung;
        }
        body.spiegelpferd & {
          @extend .icon-horse;
          font-size: rem(15px);
          color: $primarySpiegelpferd;
        }
        body.ponyseidank & {
          color: $primaryPonyseidank;
        }
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
  .twoColUl & {
    @include breakpoint(large) {
      columns: 2;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($primary, 0.1);
      padding: rem(15px);
      padding-left: rem(24px);

      body.psychosozialeBeratung & {
        background: rgba($primaryBeratung, 0.1);
      }

      body.spiegelpferd & {
        background: rgba($primarySpiegelpferd, 0.1);
      }

      body.ponyseidank & {
        background: rgba($primaryPonyseidank, 0.1);
      }
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
      border: {
        top-left-radius: $baseRadius;
        top-right-radius: $baseRadius;
      }
    }

    dd {
      padding-top: 0;
      border: {
        bottom-left-radius: $baseRadius;
        bottom-right-radius: $baseRadius;
      }

      +dt {
        margin-top: rem(10px);
      }

      +dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList {
    counter-reset: item;

    >li {
      counter-increment: item;

      &:before {
        content: counter(item) ". ";
      }
    }

    ol {
      counter-reset: item;
      margin-bottom: 0;

      >li {
        &:before {
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'],
  &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
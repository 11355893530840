.marginBottom {
  margin-bottom: rem($baseLineHeight);
}

.highlight {
  color: $light;
  background: $primary;
  padding: rem($baseGap);

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    &:last-child {
      margin-bottom: 0;
    }
  }
  body.psychologische-beratung & {
    background: $primaryBeratung;
  }
  body.spiegelpferd & {
    background: $primarySpiegelpferd;
  }
  body.ponyseidank & {
    background: $primaryPonyseidank;
  }
}

.btn {
  @extend %animatedTransform;
  background: $primary;
  border: none;
  border-radius: 50px;
  color: $light;
  cursor: pointer;
  display: inline-block;
  font-size: rem($baseFontSize);
  line-height: rem($baseLineHeight);
  padding: 0.8rem 1rem;
  text-align: center;
  text-decoration: none!important;
  font-family: $mainFont;

  &:before {
    content: map-get($iconMap, angle-right);
    font-family: $iconFont;
    font-size: rem(14px);
    color: $light;
    margin-right: rem(10px);
  }
  body.psychologische-beratung &, body.spiegelpferd &, body.ponyseidank & {
    &:hover {
      background: lighten($dark, 15%);
    }
    &:active {
      background: lighten($dark, 30%);
    }
    &:focus {
      background: lighten($dark, 45%);
    }
  }
  body.psychologische-beratung & {
    background: $primaryBeratung;
  }
  body.spiegelpferd & {
    background: $primarySpiegelpferd;
  }
  body.ponyseidank & {
    background: $primaryPonyseidank;
  }
  @include breakpoint(large) {
    padding: rem(15px) rem(30px);
  }
  &:hover {
    background: lighten($dark, 15%);
    color: $light;
  }
  &:active {
    background: lighten($dark, 30%);
    color: $light;
  }
  &:focus {
    background: lighten($dark, 45%);
    color: $light;
  }
  *[class^="icon"] {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
  &.login {
    &:before {
      content: map-get($iconMap, login);
    }
  }
  &.download {
    &:before {
      content: map-get($iconMap, download);
    }
  }
  &.pdf {
    &:before {
      content: map-get($iconMap, pdf);
    }
  }
}

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none !important;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;

  @include breakpoint(medium) {
    height: rem(400px);
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}

.WidgetStandard {
  display: block !important;
  
}
.widgetFlex {
  display: none!important;
}

.WidgetSealContainer.StickyMiddleLeft {
    bottom: 23rem;
    z-index: 999!important;
}
.WidgetSeal {
  display: block !important;
  opacity: 1!important;
}
.WidgetPopup {
  top: 55px!important;
  @include breakpoint(giant) {
		top: 140px!important;;
	}
}
.WidgetHeader {
  a {
    &:before {
      color:#fff!important;
    }
  }
}
.ponyseidank {
  .WidgetHeader {
    a {
      &:before {
        color:#fff!important;
      }
    }
  }
  
}
.psychosozialeBeratung {
  .ewcms_editor {
    a{
      &.ewcms_edit_link {
        margin-top: 40px!important;
      }
    }
    
    }

}

  

.datenschutzerklaerung {
  a {
    color: $dark;

    &:hover {
      color: $primary;
    }
  }
}
// SETTINGS

$break: 	giant; 	// break from mobile to desktop navigation
$barHeight: 50px; 	// height for quickbar
$barPos: 	top; // position (top, bottom)
$barHide: 	false; 	// if bar hides on scroll mobile (true, false)
$navHover:	true;	// hover functionality for desktop navigation

// MOBILE NAVIGATION

@include breakpoint($break, max) {
	#navigation {
		transition:transform $time;
		background-color:$light;

		body.index &, body.anfahrt &, body.impressum &, body.datenschutzerklaerung &,  {
			display: none;
		}
		.col {
			padding: 0;
		}
		.branding {
			img {
				max-height: 120px;
			}
			body.spiegelpferd & {
				img {
					max-height: 70px;

					@include breakpoint(medium) {
						max-height: 100px;
					}
				}
				.mobilBranding {
					display: none;
				}
			}
		}
		.contactHolder {
			margin-bottom: rem($baseGap);

			.phone, .email {
				justify-content: flex-start;

				&:before {
					font-family: $iconFont;
					font-size: rem(12px);
					color: $dark;
					margin-right: rem(10px);
				}
			}
			.phone {
				&:before {
					content: map-get($iconMap, phone);
				}
			}
			.email {
				&:before {
					content: map-get($iconMap, mail);
				}
			}
			.login {
				justify-content: flex-start;
				color: $light;
				background: rgba($primaryBeratung, .5);
				border-radius: 0;
			}
		}
		ul {
			background-color:$light;

			li {
				width: 100%;


				a, span {
					align-items: center;
					border-bottom: rem(1px) solid rgba($dark,0.1);
					color:$dark;
					display: flex;
					font-size:rem(16px);
					justify-content: space-between;
					line-height: rem(24px);
					padding:rem(15px) rem($baseGap);
					text-decoration: none;
					width: 100%;
					transition:background $time, color $time;

					&.sub-toggler {

						&:after {
							font-family: $iconFont;
							content: map-get($iconMap, angle-right);
						};
					}
				};

				&.navBack {
					display: none;

					span {
						justify-content: flex-start;

						&:before {
							font-family: $iconFont;
							font-size: rem(14px);
							content: map-get($iconMap, angle-right);
							transform:rotate(180deg) translateY(rem(3px));
							margin-right: rem(7px);
						}
					}
				}

				.sub {
					// &:before {
					// 	padding:rem(15px) rem($baseGap);
					// 	font-size: rem(19px);
					// 	display: block;
					// 	content:attr(data-parent-name);
					// 	background: rgba($secondary, .2);
					// }
					padding-bottom: 0;
				}
				.loginBereich, .newsletter, .kontakt, .anfahrt, .agb, .anlage1, .impressum, .datenschutzerklaerung, .loginBereichPortal {
					display: none;
				}

				// active status for all elements

				&.active > a, &.active > span, a.active, span.active {
					color:$light;
					background:$primary;

					body.psychosoziale-beratung & {
						background: $primaryBeratung;
					}
					body.spiegelpferd & {
						background: $primarySpiegelpferd;
					}
					body.ponyseidank & {
						background: $primaryPonyseidank;
					}
				}

			}
		}

		//////////////////////////////////////////////////////////////////////////////////////////// 
		//essential styles for an working mobile subnavigation
		// do not change this, unless you know what you do
		
		min-width:90%;
		max-width: 100%;
		overflow:hidden;
		overflow-y:auto;
		position:fixed;
		right:0;
	 	z-index:1002;

		@include breakpoint(medium) {
			min-width:70%;

			body.spiegelpferd & {
				max-width:70%;
			}
		}
	 	@if $barPos == "top" {
	 		bottom:0;
			top:rem($barHeight);
		} @else if $barPos == "bottom" {
			top: 0;
			bottom:rem($barHeight);
		}

	 	nav {
	 		overflow: hidden;
    		overflow-y: hidden;
    		height: 100%;

    		ul {
    			padding-bottom: rem(100px); // fix for mobile phones with a toolbar at the end
    			width:100%;

				.subToggler {
					display: none;
				}
    			&.sub {
					
				}

				&.current {
					transform:translateX(0%);

					&.open {
						z-index: 1;
						overflow:hidden;
						overflow-y: auto;
						-webkit-overflow-scrolling:touch;
					}
				}
    		}
	 	}

	 	//////////////////////////////////////////////////////////////////////////////////////////// 
	 	//////////////////////////////////////////////////////////////////////////////////////////// 
	}

	html.hiddenScroll {
		overflow:hidden;
	}
}

// DESKTOP NAVIGATION

@include breakpoint($break) {
	#navigation {
		position: fixed;
		top: 0;
		z-index: 100;
		width: 100%;
		background-color: $light;
		padding: {
			top: rem(20px);
			bottom: rem(20px);
		}
		transition: padding $time;

		body.scroll & {
			box-shadow: 0 0 10px rgba($dark, .5);
			padding: { 
				top: rem(10px);
				bottom: rem(10px);
			}
		}
		body.securedir &, body.index &, body.anfahrt &, body.impressum &, body.datenschutzerklaerung & {
			display: none;
		}
		.branding {
			img {
				max-height: 120px;
				width: auto;

				&.mobilBranding {
					@include breakpoint(giant) {
						display: none;
					}
				}
				&.desktopBranding {
					display: none;

					@include breakpoint(giant) {
						display: block;
					}
				}
			}
		}
		.contactHolder {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-bottom: rem(10px);
			transition: margin $time;

			@include breakpoint(giant) {
				margin-bottom: rem(16px);
			}
			li {
				&:not(:last-child) {
					margin-right: rem(20px);
					transition: margin $time;

					@include breakpoint(huge) {
						margin-right: rem(35px);
					}
					@include breakpoint(full) {
						margin-right: rem(50px);
					}
				}
				a {
					&:not(.btn) {
						color: $medium;

						&:hover {
							color: $primary;

							body.psychosoziale-beratung & {
								color: $primaryBeratung;
							}
							body.spiegelpferd & {
								color: $primarySpiegelpferd;
							}
							body.ponyseidank & {
								color: $primaryPonyseidank;
							}
						}
					}
				}
				.phone, .email {
					&:before {
						font-family: $iconFont;
						font-size: rem(14px);
						color: $medium;
						margin-right: rem(10px);
					}
				}
				.phone {
					&:before {
						content: map-get($iconMap, phone);
					}
				}
				.email {
					text-decoration: none;
					
					&:before {
						content: map-get($iconMap, mail);
					}
				}
				.btn {
					padding: {
						top: rem(6px);
						bottom: rem(6px);
					}
				}
			}
		}
		nav {
			.navi {
				display: flex;
				justify-content: space-between;

				// only first level <li>

				> li {
					> a, > span {
						
					}

					// active and hover status for all first level elements
					&:hover > a, &:hover > span,  > a.active, > span.active {
						color: $primary;

						body.psychosoziale-beratung & {
							color: $primaryBeratung;
						}
						body.spiegelpferd & {
							color: $primarySpiegelpferd;
						}
						body.ponyseidank & {
							color: $primaryPonyseidank;
						}
					}
					@if $navHover {
				 		body.desktop & {
							&:hover {
								> .sub {
									top:100%;
									opacity: 1;
									visibility: visible;
									pointer-events: auto;
								}
							}
							.navBack {
								display: none;
							}
						}
					};
					&.newsletter, &.anfahrt, &.kontakt, &.impressum, &.datenschutzerklaerung, &.loginBereichPortal {
						display: none;
					}
				}

				li {
					display: flex;
					align-items: center;
					position: relative;

					a, span {
						white-space: nowrap;
						color:$dark;
						display: flex;
						align-items: center;
						text-decoration: none;
						cursor: pointer;
						font-size:rem(16px);
						line-height: 100%;
						position: relative;
						width: 100%;

						@include breakpoint(huge) {
							font-size:rem(18px);
						}
						body.ponyseidank & {
							font-size:rem(14px);

							@include breakpoint(huge) {
								font-size:rem(15px);
							}
							@include breakpoint(full) {
								font-size:rem(16px);
							}
						}
						&.subToggler {
							display: none;

							&:after {
								font-family: $iconFont;
								font-size: rem(14px);
								content: map-get($iconMap, angle-right);
								transform:rotate(90deg) translateX(2px);
								margin-left: rem(10px);
							};
						}
					}

					&.navBack {
						display: none;
						text-align: center;

						span {
							display: none;
						}
						&:before {
							cursor: pointer;
							width: 100%;
							font-family: $iconFont;
							content: map-get($iconMap, times);
							display: block;
							font-size: rem(16px);
							padding:rem(5px) rem($baseGap);
							background:$medium;
							transition:background $time;
						}
						&:hover {

							&:before {
								background:darken($medium,10%);
							}
						}
					}
					&.hasSub {
						display: flex;
						justify-content: space-between;
						width: 100%;
					}
					.sub {
						display: flex;
    					justify-content: space-between;
						width: 100%;
						// background:$light;
						// position: absolute;
						// top:120%;
						// left:0;
						// min-width: 100%;
						// opacity: 0;
						// visibility: hidden;
						// pointer-events: none;
						transition:opacity $time, visibility $time, top $time;
						// box-shadow:0 0 rem(10px) rgba(black,0.05);

						li {
							a, span {
								transition:background $time;
								// padding:rem(15px) rem($baseGap);

								&:before {
									position: absolute;
									top:100%;
									width: 40%;
									height: rem(1px);
									background:$primaryPonyseidank;
									content:'';
									opacity: 0;
									left: 0;
									visibility: hidden;
									transition:opacity $time, visibility $time, top $time;
								}
							}
							// active and hover status for all sub a elements

							a.active, span.active, &:hover > a, &:hover > span, a:hover, span:hover {
								color: $primary;

								body.psychosoziale-beratung & {
									color: $primaryBeratung;
								}
								body.spiegelpferd & {
									color: $primarySpiegelpferd;
								}
								body.ponyseidank & {
									color: $primaryPonyseidank;

									&:before {
										top:-50%;
										opacity: 1;
										visibility: visible;
									}
								}
							}
							@if $navHover {
						 		body.desktop & {
									&:hover {
										> .sub {
											top:0%;
											opacity: 1;
											visibility: visible;
											pointer-events: auto;
										}
									}

									.navBack {
										display: none;
									}
								}
							};
							&.newsletter {
								display: none;
							}
							body.psychosozialeBeratung & {
								&.anlage1, &.agb, &.kontakt, &.loginBereich {
									display: none;
								}
							}
							body.spiegelpferd &, body.ponyseidank & {
								&.kontakt {
									display: none;
								}
							}
						}

						&.current {
							top:100%;
							opacity: 1;
							visibility: visible;
							pointer-events: auto;
						}
						&.open {
							top:100%;
						}
						.sub {
							top:20%;
							left:100%;

							&.current, &.open {
								top:0;
							}
						}
					}
				}
			}
		}
	}
}

// MOBILE QUICKBAR

@include breakpoint($break, max) {
	#quickbar {
		box-shadow:0 0 rem(10px) rgba(black,0.1);
		height:rem($barHeight);
		background-color:$light;
		display:flex;
		justify-content:space-between;
		align-items:center;
		z-index:1000;
		position:fixed;
		left:0;
		width:100%;
		padding:0 rem($baseGap);
		transform:translateY(0%);
		transition:transform $time;

		@if $barPos == "top" {
			top: 0;
			@if $barHide {
				html:not(.hidden-scroll) body.scrolling-down & {
					transform: translateY(-100%);
				}
			}
		} @else if $barPos == "bottom" {
			bottom: 0;
			@if $barHide {
				html:not(.hidden-scroll) body.scrolling-down & {
					transform: translateY(100%);
				}
			}
		}
		body.index &, body.anfahrt &, body.impressum &, body.datenschutzerklaerung &,  {
			display: none;
		}
		.branding {
			img {
				height: 40px;
				width: auto;
			}
		}
		label[for="naviToggled"] {
			display:block;
			cursor:pointer;
			width:rem(20px);
			height:rem(20px);
			position:relative;
			overflow:hidden;

			&:after{
				content:"";
				position:fixed;
				left:0;
				width:200vw;
				height:200vh;
				display:block;
				background-color:rgba($dark,0.9);
				z-index:-1;
				transition: opacity $time, visibility $time;

				@if $barPos == "top" {
					top:rem($barHeight);
				} @else if $barPos == "bottom" {
					bottom:rem($barHeight);
				}
			}
			span {
				transition:0.4s linear;
				text-indent:-9999px;
				width:0;
				top:50%;
				transform:translate(-50%, -50%);

				&:before, &:after {
					top:50%;
					width:rem(20px);
					transition:top $time ease-in-out, transform $time 0.2s linear;
				}

				&:before {
					transform:translate(-50%, -50%) rotate(45deg);
				};

				&:after {
					transform:translate(-50%, -50%) rotate(-45deg)
				};
			}
			span, span:before, span:after {
				content:"";
				position:absolute;
				display:block;
				background:$dark;
				height:2px;
				left:50%;
				transform-origin:center
			};
		};
	};
	
	#naviToggled {
		&:not(:checked) {
	 		& ~ * #navigation,
	 		& ~ #navigation {
				transform:translateX(100%);
	 		};
	 		& ~ #quickbar {
				label[for="naviToggled"] {
					span {
						width:20px;

						&:before, &:after {
							transform:translate(-50%, -50%);
							transition:top $time 0.2s ease-in-out, transform $time linear;
						};
						&:before {
							top:calc(50% - 5px);
						};
						&:after {
							top:calc(50% + 5px);
						};
					}
					&:after {
						opacity:0;
		 				visibility:hidden
					};
				}
	 		};
	 	};
	}
}

#naviToggled {
	display:none;
};

@include breakpoint($break) {
	#quickbar {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: flex;
	flex-direction: column;
	align-items: center;
	
	@include breakpoint(huge) {
		flex-direction: row;
		justify-content: flex-end;
	}
	li {
		width: 100%;
		text-align: center;
		border-top: 1px solid rgba($medium, .2);
		padding: rem(12px) 0;

		@include breakpoint(huge) {
			width: auto;
			text-align: inherit;
			border-top: none;
			padding:0;
		}
		&:not(:first-child) {
			@include breakpoint(huge) {
				margin-left: rem(30px);
			}
		}
		&.active, &:hover {
			a {
				color: $primary;

				body.psychosoziale-beratung & {
					color: $primaryBeratung;
				}
				body.spiegelpferd & {
					color: $primarySpiegelpferd;
				}
				body.ponyseidank & {
					color: $primaryPonyseidank;
				}
			}
		}
		a {
			color: $dark;
			text-decoration: none;
		}
	}
}
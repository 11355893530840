// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $primary;
$collapser-dark: $dark;

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {
    #{$collapser-class} {
        background: rgba($collapser-color, .2);
        position: relative;
        cursor: pointer;
        padding: $collapser-gaps-tb 78px $collapser-gaps-tb $collapser-gaps-lr;

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $collapser-dark;
            width:15px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.cms-container-el {
                min-height: inherit;
            }
            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {
    border-radius: $baseRadius;
    background: rgba($primary, .2);

    body.psychosozialeBeratung & {
        background: rgba($primaryBeratung, .2);
    }
    body.spiegelpferd & {
        background: rgba($primarySpiegelpferd, .2);
    }
    body.ponyseidank & {
        background: rgba($primaryPonyseidank, .2);
    }
    &:after, &:before {
        background: $primary;

        body.psychosozialeBeratung & {
            background: $primaryBeratung;
        }
        body.spiegelpferd & {
            background: $primarySpiegelpferd;
        }
        body.ponyseidank & {
            background: $primaryPonyseidank;
        }
    }
}
.cmsBackend {
    .cmsInvisble,
    #navigation,
    #header,
    #footer {
        display: none;
    }
}
.ewcms_edit_img {width: auto!important;}
.ewcms_loopline_delimg{width: 15px!important;}

// Pixel Werte Section
$bpTiny: 60px;
$bpSmall: 60px;
$bpMedium: 100px;
$bpHuge: 120px;
$bpFull: 150px;
// Pixel Werte SectionSmall
$bpTinySS: 60px;
$bpSmallSS: 40px;
$bpMediumSS: 60px;
$bpHugeSS: 80px;
$bpFullSS: 100px;

@mixin section {
    padding-top: rem($bpTiny);
    padding-bottom: calc(#{rem($bpTiny)});

    @include breakpoint(small) {
        padding-top: rem($bpSmall);
        padding-bottom: calc(#{rem($bpSmall - $baseLineHeight)});
    }

    @include breakpoint(medium) {
        padding-top: rem($bpMedium);
        padding-bottom: calc(#{rem($bpMedium - $baseLineHeight)});
    }

    @include breakpoint(huge) {
        padding-top: rem($bpHuge);
        padding-bottom: calc(#{rem($bpHuge - $baseLineHeight)});
    }

    @include breakpoint(full) {
        padding-top: rem($bpFull);
        padding-bottom: calc(#{rem($bpFull - $baseLineHeight)});
    }
}

@mixin sectionFull {
    padding-top: rem($bpTiny);
    padding-bottom: rem($bpTiny);

    @include breakpoint(small) {
        padding-top: rem($bpSmall);
        padding-bottom: rem($bpSmall);
    }

    @include breakpoint(medium) {
        padding-top: rem($bpMedium);
        padding-bottom: rem($bpMedium);
    }

    @include breakpoint(huge) {
        padding-top: rem($bpHuge);
        padding-bottom: rem($bpHuge);
    }

    @include breakpoint(full) {
        padding-top: rem($bpFull);
        padding-bottom: rem($bpFull);
    }
}

@mixin sectionSmall {
    padding-top: rem($bpTinySS);
    padding-bottom: calc(#{rem($bpTinySS)});

    @include breakpoint(small) {
        padding-top: rem($bpSmallSS);
        padding-bottom: calc(#{rem($bpSmallSS - $baseLineHeight)});
    }

    @include breakpoint(medium) {
        padding-top: rem($bpMediumSS);
        padding-bottom: calc(#{rem($bpMediumSS - $baseLineHeight)});
    }

    @include breakpoint(huge) {
        padding-top: rem($bpHugeSS);
        padding-bottom: calc(#{rem($bpHugeSS - $baseLineHeight)});
    }

    @include breakpoint(full) {
        padding-top: rem($bpFullSS);
        padding-bottom: calc(#{rem($bpFullSS - $baseLineHeight)});
    }
}

@mixin sectionSmallFull {
    padding-top: rem($bpTinySS);
    padding-bottom: rem($bpTinySS);

    @include breakpoint(small) {
        padding-top: rem($bpSmallSS);
        padding-bottom: rem($bpSmallSS);
    }

    @include breakpoint(medium) {
        padding-top: rem($bpMediumSS);
        padding-bottom: rem($bpMediumSS);
    }

    @include breakpoint(huge) {
        padding-top: rem($bpHugeSS);
        padding-bottom: rem($bpHugeSS);
    }

    @include breakpoint(full) {
        padding-top: rem($bpFullSS);
        padding-bottom: rem($bpFullSS);
    }
}